<template>
    <a-spin :spinning="loading">
        <div class="my-md" v-if="imageUrl">
            <!--        <vue-qr :logoSrc="imageUrl" :text="text" :size="200" :margin="0" :logoScale="0.2"></vue-qr>-->
            <div style="text-align: center">
                <h1><b>入职二维码</b></h1>
            </div>
            <div style="text-align: center">
                <img :src="imageUrl" style="width: 200px;"/>
            </div>

        </div>
    </a-spin>
</template>

<script>
    import {ModalComponentBase} from "../../../../shared/component-base";
    import vueQr from 'vue-qr'
    import {AppConsts} from "../../../../abpPro/AppConsts";
    import {HandLeentryServiceProxy} from "../../../../shared/service-proxies";

    export default {
        name: "handleentry-qrcode",
        mixins:[ModalComponentBase],
        components:{vueQr},
        data(){
            return {
                imageUrl: "",
                text:AppConsts.EntryUrl
            }
        },
        created() {
            this._handLeentryServiceProxy=new HandLeentryServiceProxy(this.$apiUrl,this.$api);
        },
        mounted() {
        this.getData();
        },
        methods:{
            getData(){
                this.loading=true;
                this._handLeentryServiceProxy.getWXAppImg()
                .finally(()=>{this.loading=false})
                .then(res=>{
                    this.imageUrl=res;
                })
            }
        }

    }
</script>

<style scoped>

</style>
