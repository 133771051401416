<template>
	<a-spin :spinning="spinning">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="id">{{ l('Handleentry') }}</span>
				<span v-else>{{ l('CreateShiftinfo') }}</span>
			</div>
		</div>
		<a-config-provider :locale="zh_CN">
			<a-row :gutter="8" class="box">
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('Company')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-tree-select
							@change="companyChange"
							:placeholder="l('Company')"
							style="width: 100%"
							allow-clear
							tree-default-expand-all
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							:tree-data="companyTreeList"
							v-model="CompanyId"
						/>
					</a-col>
					<a-col :span="4">
						<q-label required :label="l('Department')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-tree-select
							v-model="DepartmentId"
							style="width: 100%"
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							:tree-data="DepTreeData"
							:placeholder="l('Department')"
							allow-clear
							@change="SelectDepartmentTree"
						>
						</a-tree-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('UserName')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input v-model="user.userName" :placeholder="l('UserName')" />
					</a-col>
					<a-col :span="4">
						<q-label required :label="l('JobNumber')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input v-model="employee.jobNumber" :placeholder="l('JobNumber')" />
					</a-col>
				</a-row>

				<a-row>
					<a-col :span="4">
						<q-label required :label="l('Password')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-password v-model="user.password" :placeholder="l('Password')" />
					</a-col>
					<a-col :span="4">
						<q-label required :label="l('HireDate')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker :allowClear="false" v-model="employee.hireDate" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('PasswordRepeat')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-password v-model="passwordRepeat" :placeholder="l('PasswordRepeat')" />
					</a-col>
					<a-col :span="4">
						<q-label required :label="l('ProbationMonth')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							v-model="employee.probationMonth"
							:placeholder="l('ProbationMonth')"
							style="width:100%"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('RealName')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input v-model="employee.realName" :placeholder="l('RealName')" />
					</a-col>
					<a-col :span="4">
						<q-label :label="l('EmailAddress')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input v-model="employee.personalEmail" :placeholder="l('EmailAddress')" />
					</a-col>
				</a-row>
			</a-row>
		</a-config-provider>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button @click="close" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :type="'primary'" @click="save">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import { ModalComponentBase } from '../../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { Dic } from '../../../../shared/utils';
import jss from '../../../../../libs/abp/abp';
import moment from 'moment';
import {
	CompanyServiceProxy,
	DepartmentServiceProxy,
	HandLeentryServiceProxy,
	UserEditDto,
	CreateOrUpdateUserInput,
	EmployeeEditDto,
	EmployeeServiceProxy,
} from '../../../../shared/service-proxies/service-proxies';
import { data } from 'jquery';
import { count } from 'rxjs/operators';
import { CreateOrEditHandLenntryInput } from '../../../../shared/service-proxies';
import Reg from '../../../../shared/utils/reg';

export default {
	name: 'create-handleenty',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			spinning: false,
			id: null,
			user: new UserEditDto(),
			passwordRepeat: undefined,
			companyTreeList: [],
			DepTreeData: [],
			CompanyId: null,
			DepartmentId: null,
			employee: new EmployeeEditDto(),
		};
	},
	created() {
		this.fullData();
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._HandLeentryServiceProxy = new HandLeentryServiceProxy(this.$apiUrl, this.$api);
		this._employeeServiceProxy = new EmployeeServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.companyTreeInit();
		if (this.id) {
			this.getData();
			if ((this.CompanyId, this.DepartmentId)) {
				this.getDepTreeData(this.CompanyId, this.DepartmentId);
			}
		}
	},
	methods: {
		getData() {
			this.spinning = true;
			this._HandLeentryServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.employee = res.employee;
					this.user = res.user;
					this.CompanyId = this.employee.companyId;
					if (this.CompanyId && !this.employee.jobNumber) {
						this.companyChange(this.CompanyId);
						this.getNewUserJobNumber();
					}
					this.DepartmentId = this.employee.departmentId;
				});
		},
		/**
		 * 根据公司ID获取随机工号
		 */
		getNewUserJobNumber() {
			this.spinning = true;
			this._employeeServiceProxy
				.getNewEmpJobNumber(this.CompanyId)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.user.userName = res.newEmpJobNumber;
					this.employee.jobNumber = res.newEmpJobNumber;
				});
		},
		save() {
			let input = new CreateOrEditHandLenntryInput();
			input.user = this.user;
			this.employee.departmentId = this.DepartmentId;
			this.employee.companyId = this.CompanyId;
			input.employee = this.employee;
			this.user.realName = this.employee.realName;
			this.checkErr();
			this.spinning = true;
			this._HandLeentryServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
		checkErr() {
			if (!this.employee.companyId) {
				return abp.message.warn('请选择公司');
			}
			if (!this.employee.departmentId) {
				return abp.message.warn('请选择部门');
			}
			if (!this.user.userName) {
				return abp.message.warn('请输入账号');
			}
			if (!this.employee.jobNumber) {
				return abp.message.warn('请输入工号');
			}
			if (!this.user.password) {
				return abp.message.warn('请输入密码');
			}
			if (this.user.password.length < 6) {
				return abp.message.warn('密码位数不能少于六位');
			}
			if (!this.passwordRepeat) {
				return abp.message.warn('请输入核对密码');
			}
			if (this.user.password != this.passwordRepeat) {
				return abp.message.warn('两次密码输入不一致');
			}
			if (!this.employee.hireDate) {
				return abp.message.warn('请选择入职时间');
			}
			if (!this.employee.probationMonth) {
				return abp.message.warn('请输入试用期');
			}
			if (!this.employee.realName) {
				return abp.message.warn('请输入姓名');
			}
			if (this.employee.personalEmail) {
				if (!Reg.getInstance().RegEmail(this.employee.personalEmail)) {
					return abp.message.warn('邮箱格式错误');
				}
			}
		},
		companyTreeInit() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		companyChange(value) {
			this.CompanyId = value;
			if (this.CompanyId) {
				this.getDepTreeData();
			} else {
				this.DepartmentId = undefined;
				this.DepTreeData = [];
			}
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			//this.DepartmentId = undefined;
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.CompanyId)
				.then((res) => {
					this.DepTree = res;
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							let obj = {
								key: item.key,
								value: item.key.toString(),
								title: item.title,
							};
							this.DepTreeData.push(obj);
						}
					});
					this.initDepTree(this.DepTreeData);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initDepTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.DepTree.forEach((m) => {
					if (m.parentId == v.key) {
						//根据主键查子集数据
						var obj = {
							key: m.key,
							value: m.key.toString(),
							title: m.title,
						};
						v.children.push(obj); //放进父级的集合中
						this.initDepTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.DepartmentId = e;
		},
	},
};
</script>

<style scoped></style>
