<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="[8, 8]">
				<a-col :span="7" style="width:360px">
					<a-button type="primary" @click="handleentry" v-if="isGranted('handleFormal')">
						<a-icon type="form" />
						办理入职
					</a-button>
					<a-button type="primary" @click="qrcodeView" v-if="isGranted('qrView')">
						<a-icon type="qrcode" />
						扫码入职
					</a-button>
					<a-button :type="'primary'" v-if="isGranted('handleentry_exe')">
						<a href="QYBClient:123-MonacoGP-19279-Senna">{{ l('证件入职制卡') }}</a>
					</a-button>
				</a-col>
				<a-col :span="4">
					待入职员工(共<b>{{ totalItems }}</b
					>人)
				</a-col>

				<a-col :span="4" style="padding-top: 3px;float:right">
					<a-input-search
						style="width: 100%"
						name="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="search"
						enterButton
						v-model="filterText"
					/>
				</a-col>

				<a-col :span="4" style="float:right">
					<a-tree-select
						@change="companyChange"
						:placeholder="l('Company')"
						style="width: 100%"
						allow-clear
						tree-default-expand-all
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="CompanyId"
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				::customRow="customRow"
				:rowClassName="rowClassName"
			>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import { CompanyServiceProxy, DepartmentServiceProxy, HandLeentryServiceProxy } from '../../../shared/service-proxies';
import { Dic } from '../../../shared/utils';
import moment from 'moment';
import { ModalHelper } from '@/shared/helpers';
import CreateHandLeenty from '../handleentry/create-handleenty/create-handleenty.vue';
import HandleentryQrcode from './handleentry-qrcode/handleentry-qrcode';
import ViewUserComponent from '../../admin/users/view-user/view-user';
import { abpService } from '../../../shared/abp';

export default {
	name: 'handleentry',
	mixins: [AppComponentBase],
	components: { CreateHandLeenty, HandleentryQrcode },
	data() {
		return {
			columns: [
				{
					title: this.l('RealName'),
					dataIndex: 'realName',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: this.l('Gender'),
					dataIndex: 'genderCode',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'genderCode' },
				},
				{
					title: this.l('Mobile'),
					dataIndex: 'mobile',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'mobile' },
				},
				{
					title: this.l('Email'),
					dataIndex: 'personalEmail',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'emailAddress' },
				},
				{
					title: this.l('HireDate'),
					dataIndex: 'hireDate',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'hireDate' },
				},
				// {
				//     title: this.l("Actions"),
				//     dataIndex: "actions",
				//     ellipsis: true,
				//     sorter: false,
				//     align: "center",
				//     scopedSlots: {customRender: "actions"}
				// }
			],
			tableData: [],
			companyTreeList: [],
			CompanyId: undefined,
			sexList: [],
		};
	},
	created() {
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._HandLeentryServiceProxy = new HandLeentryServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.companyTreeInit();
		if (abpService.abp.userInfo.companyId) {
			this.CompanyId = abpService.abp.userInfo.companyId;
			this.companyChange(this.CompanyId);
		}
		this.sexList = await Dic.getInstance().getDicAsync('gender');
		this.getData();
	},

	watch: {
		CompanyId: function(value) {
			this.getData();
		},
	},

	methods: {
		/**
		 * 获取人员信息
		 */
		getData() {
			this.selectedRowObj = {};

			this.loading = true;
			this._HandLeentryServiceProxy
				.getPaged(
					this.CompanyId,
					this.DepartmentId,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.hireDate = item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : ' ';
					});
					this.totalItems = res.totalCount;
				});
		},

		/**
		 * 扫码入职
		 */
		qrcodeView() {
			ModalHelper.create(
				HandleentryQrcode,
				{},
				{
					width: '300px',
				}
			).subscribe((res) => {});
		},
		/**
		 * 办理入职
		 */
		handleentry() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			ModalHelper.create(
				ViewUserComponent,
				{ EmpId: this.selectedRows[0].id, companyId: this.CompanyId ? this.CompanyId : undefined, type: 1 },
				{
					width: '1000px',
				}
			).subscribe((res) => {
				this.getData();
			});
		},
		/**
		 * 编辑入职
		 */
		handleFormal(record) {
			this.id = record.id;
			ModalHelper.create(CreateHandLeenty, { id: this.id }, { width: '900px' }).subscribe((res) => {
				if (res) {
					this.getData();
				}
			});
		},

		companyTreeInit() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		companyChange(value) {
			this.CompanyId = value;
			this.pageNumber = 1;
			this.request.skipCount = 0;
			if (this.CompanyId) {
				this.getData();
			}
		},
	},
};
</script>

<style scoped></style>
